<template>
  <div class="map fill-height">
    <ModelsMap v-if="!destroyMap" :x="2.395020" :y="46.965259" :zoom="5" />
  </div>
</template>

<script>
// @ is an alias to /src
import ModelsMap from '@/components/ModelsMap.vue'

export default {
  name: 'Map',
  components: {
    ModelsMap
  },
  data(){
    return {
      destroyMap: false
    }
  },
  beforeDestroy() {
    this.destroyMap = true
  }
}
</script>
<style scoped>
</style>