<template>
  <div class="fill-height">
    <v-expand-x-transition>
      <v-chip class="preloader secondary" v-show="loading"> 
        <div>
          <v-expand-x-transition>
            <v-progress-circular
              v-show="loading"
              :value="loadingProgress"
              :indeterminate="indeterminateLoading"
              color="accent"
              size="25"
              class="float-left"
            >
            </v-progress-circular>
          </v-expand-x-transition>
          <div class="text-caption text-center loadingCaption">{{this.currentlyLoading}}</div>
        </div>
      </v-chip>
    </v-expand-x-transition>
    <div class="map-toolbox">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on:click="saveCurrentExtent" v-bind="attrs" v-on="on" height="48px" width="48px" class="btn-save-map-state"><v-icon>mdi-map-check-outline</v-icon></v-btn>
        </template>
        <span>{{$t('saveMapPosition')}}</span>
      </v-tooltip>
      <v-expansion-panels class="ips-selector">
        <v-expansion-panel>
          <v-expansion-panel-header v-if="this.selectedScenario && this.selectedScenario !== this.$t('observ')">
            {{$t('situation')}} {{$t('on')}} {{this.ipsDateTitle}} ({{this.selectedScenario.replace(this.$t('forecast'), '').charAt(0).toUpperCase() + this.selectedScenario.replace(this.$t('forecast'), '').slice(1)}}) 
          </v-expansion-panel-header>
          <v-expansion-panel-header v-else>
            {{$t('situation')}} {{$t('on')}} {{this.ipsDateTitle}} ({{$t(this.ipsTitle)}})
          </v-expansion-panel-header>

          <v-expansion-panel-content class="text-center">
            <v-date-picker first-day-of-week="1" :locale="$t('calendar_language')" v-model="ipsDate"></v-date-picker>
            <v-select
              :disabled="new Date(ipsDate).toLocaleDateString() === new Date().toLocaleDateString()"
              :items="availableScenarios"
              v-model="selectedScenario"
              :label="$t('scenario')"
            ></v-select>        
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="warning" v-bind="attrs" v-on="on" :disabled="new Date(ipsDate).toLocaleDateString() === new Date().toLocaleDateString()" v-on:click="resetIPS"><v-icon>mdi-page-first</v-icon></v-btn>
              </template>
              <span>{{$t('actualSituation')}}</span>
            </v-tooltip>
            &nbsp;
            <v-btn class="accent" :disabled="!selectedScenario || !ipsDate" v-on:click="IPSDateChanged">{{$t('refreshSituation')}}</v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div class="map-legends">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{$t('legendSituation')}}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-center">
            <div class="legende ips">
              <div class="legend-title">
                {{$t('trend')}}
              </div>
              <div class="legend-item">
                <img class="grayscale" src="/images/ips/hausse-moderement-bas.png" /> {{$t('growingTrend')}}
              </div>
              <div class="legend-item">
                <img class="grayscale" src="/images/ips/stable-moderement-bas.png" /> {{$t('stableTrend')}}
              </div>
              <div class="legend-item">
                <img class="grayscale" src="/images/ips/baisse-moderement-bas.png" /> {{$t('downTrend')}}
              </div>
              <div class="legend-title">
                {{$t('levels')}}
              </div>
              <div class="legend-item">
                <img src="/images/ips/stable-tres-haut.png" /> {{$t('veryhighlevel')}}
              </div>
              <div class="legend-item">
                <img src="/images/ips/stable-haut.png" /> {{$t('highlevel')}}
              </div>
              <div class="legend-item">
                <img src="/images/ips/stable-moderement-haut.png" /> {{$t('modhighlevel')}}
              </div>
              <div class="legend-item">
                <img src="/images/ips/stable-moyenne.png" /> {{$t('meanlevel')}}
              </div>
              <div class="legend-item">
                <img src="/images/ips/stable-moderement-bas.png" /> {{$t('modlowlevel')}}
              </div>
              <div class="legend-item">
                <img src="/images/ips/stable-bas.png" /> {{$t('lowlevel')}}
              </div>
              <div class="legend-item">
                <img src="/images/ips/stable-tres-bas.png" /> {{$t('verylowlevel')}}
              </div>
              <div class="legend-item"> 
                <img src="/images/ips/inconnu-inconnu.png" /> {{$t('unknownlevel')}}
              </div>
            </div>
            <div class="legende decrees" v-if="decreesL != null">
              <div class="legend-title">
                {{$t('Decrees')}} {{ $t('postDecrees') }}
              </div>
              <div class="legend-item">
                <img class="grayscale" src="/images/decrees/vigilance.png" /> Vigilance
              </div>
              <div class="legend-item">
                <img class="grayscale" src="/images/decrees/alerte.png" /> Alerte
              </div>
              <div class="legend-item">
                <img class="grayscale" src="/images/decrees/renforcer.png" /> Alerte renforcée
              </div>
              <div class="legend-item">
                <img class="grayscale" src="/images/decrees/crise.png" /> Crise
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div class="mapsearch">
      <v-autocomplete 
        v-model="searchModel"
        :items="searchItems"
        :loading="isSearchLoading"
        :search-input.sync="search"
        hide-no-data
        solo 
        clearable 
        chips
        item-text="properties.label"
        item-value="geometry"
        :label="this.$t('searchMap')"
        prepend-inner-icon="mdi-magnify">
          <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{$t('searchMap')}}
                </v-list-item-title>
              </v-list-item>
          </template>
          <template v-slot:selection="{ attr, on, item, selected }">
            <v-chip
              v-bind="attr"
              :input-value="selected"
              color="blue-grey"
              class="white--text"
              v-on="on"
            >
              <v-icon small left v-if="item.properties.codeBss">
                mdi-triangle
              </v-icon>
              <v-icon small left v-else>
                mdi-map-marker
              </v-icon>
              <span v-text="item.properties.label"></span>
            </v-chip>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-icon>
              <v-icon small left v-if="item.properties.codeBss">
                mdi-triangle
              </v-icon>
              <v-icon small left v-else>
                mdi-map-marker
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.properties.label"></v-list-item-title>
              <v-list-item-subtitle v-if="item.properties.bssId" v-text="item.properties.bssId"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
    </div>
    <div class="modelsmap fill-height" id="modelsMap">
    </div>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      style="width: 395px">
      <template v-if="selectedModel != null">
          <v-list-item :style="`background-color: ${selectedModel.ips.couleur};`">
            <v-list-item-content style="height: 90px">
              <v-list-item-subtitle v-if="this.piezo.capteur.altIdentifier == null || this.piezo.capteur.altIdentifier.length == 0 " :style="`text-align: center; white-space: normal; color: ${pickTextColorBasedOnBgColorAdvanced(selectedModel.ips.couleur, '#fff', '#000')}`">{{ selectedModel.libelle }}</v-list-item-subtitle>
              <v-list-item-subtitle v-else :style="`text-align: center; white-space: normal; color: ${pickTextColorBasedOnBgColorAdvanced(selectedModel.ips.couleur, '#fff', '#000')}`">{{ selectedModel.libelle }} ({{this.piezo.capteur.altIdentifier }})</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon style="margin-top: 33px">
              <v-icon @click="closeDrawer()" right :color="pickTextColorBasedOnBgColorAdvanced(selectedModel.ips.couleur, '#fff', '#000')">mdi-close</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
          <v-list dense>
            <v-list-item v-if="this.piezo.capteur.source.libelle.toLowerCase().replace(/\s/g,'') != 'producteurtiershorsfrance'">
              <v-list-item-icon>
                <v-icon>mdi-information-outline</v-icon>
              </v-list-item-icon>
              
              <v-list-item-content>
                <v-list-item-title>
                  {{$t('lastDataAdes')}}
                </v-list-item-title>
                <v-list-item-subtitle v-if="this.latestAdes">
                  {{ this.latestAdes }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  {{$t('unknown')}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-information-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{$t('lastDataRealTime')}}
                </v-list-item-title>
                <v-list-item-subtitle v-if="this.latestAdes">
                  {{ this.latestTempsReel }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  {{$t('unknown')}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <!--<v-list-item>
              <v-list-item-content>
                <v-list-item-title>Données associées</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="(s, k) in selectedModel.donneeModele" :key="k">
              <v-list-item-icon>
                <v-icon>mdi-map-marker</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ s.capteur.typeDonnee.type }} ({{ s.capteur.typeDonnee.unite }}) : {{ s.capteur.identifier }}
              </v-list-item-content>
            </v-list-item>-->
          </v-list>
          <v-divider></v-divider>
            <v-list dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-if="this.selectedScenario && this.selectedScenario !== this.$t('observ')">
                    {{$t('situation')}} {{$t('on')}} {{this.ipsDateTitle}} ({{this.selectedScenario.replace(this.$t('forecast'), '').charAt(0).toUpperCase() + this.selectedScenario.replace(this.$t('forecast'), '').slice(1)}}) 
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    {{$t('situation')}} {{$t('on')}} {{this.ipsDateTitle}} ({{$t(this.ipsTitle)}})
                  </v-list-item-title>
          
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <span v-html="selectedModel.ips.symbole" class="ips-icon" :style="'color: ' + selectedModel.ips.couleur"></span>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-chip>{{selectedModel.ips.libelleClasse.toLowerCase() === $t('unknown').toLowerCase() ? $t('unknownlevel'): $t('levels') +" "+$t(selectedModel.ips.libelleClasse.replace(" ","")) }} ({{ selectedModel.ips.tendance === 'hausse' || selectedModel.ips.tendance === 'baisse' ? $t('en ' + selectedModel.ips.tendance) : $t(selectedModel.ips.tendance) }})</v-chip>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-btn :to="'/model/' + selectedModel.id">{{$t('seeFile')}}</v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>
      </template>
      <template v-if="selectedDecrees != null">
          <v-list-item :style="`background-color:${this.getColorAlert(selectedDecrees.numeroNiveau)}`">
            <v-list-item-content style="height: 90px">
              <v-list-item-title style="`text-align: center; white-space: normal;`">{{ selectedDecrees.nomArrete.replaceAll("_", " ") }}</v-list-item-title>
              <v-list-item-subtitle style="`text-align: center; white-space: normal;`">Niveau d'alerte: {{ selectedDecrees.nomNiveau }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon style="margin-top: 33px">
              <v-icon @click="closeDrawer()" right>mdi-close</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
          <v-list dense>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-information-outline</v-icon>
              </v-list-item-icon>            
              <v-list-item-content>
                <v-list-item-title style="`text-align: center; white-space: normal;">
                  {{ selectedDecrees.zoneArrete.nomZone }} 
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t(selectedDecrees.zoneArrete.typeZone) }}
                </v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-information-outline</v-icon>
              </v-list-item-icon>   
            <v-list-item-content>             
              <v-list-item-title>
                  <p> {{ selectedDecrees.zoneArrete.nomDep }} ({{ selectedDecrees.zoneArrete.codeDep }})</p>
                </v-list-item-title>
              </v-list-item-content>

            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-information-outline</v-icon>
              </v-list-item-icon>   
            <v-list-item-content>             
              <v-list-item-title>
                  {{ selectedDecrees.dateDebut }} 
                </v-list-item-title>
                <v-list-item-title>
                  {{ selectedDecrees.dateFin }}
                </v-list-item-title>
              </v-list-item-content>

            </v-list-item>
          </v-list>
          <v-divider></v-divider>
            <v-list dense>
              <v-list-item>
                <v-list-item-content>
                <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn  small outlined color="info" @click="downloadButtonAction(selectedDecrees.cheminTelechargement)" v-bind="attrs" v-on="on"><v-icon>mdi-file-download-outline</v-icon></v-btn>
                </template>
                <span> {{$t('downloadDecrees')}} </span>
                </v-tooltip>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          <v-divider></v-divider>
      </template>
    </v-navigation-drawer>

    <v-navigation-drawer
        v-model="drawerBSH"
        absolute
        style="width: 395px">
      <!-- <template v-if="selectedBSH != null">
        <v-list-item :style="`background-color: ${selectedBSH.fill};`">
          <v-list-item-content style="height: 90px">
            <v-list-item-subtitle :style="`text-align: center; white-space: normal; color: ${pickTextColorBasedOnBgColorAdvanced(selectedBSH.fill, '#fff', '#000')}`">{{ selectedBSH.nom }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon style="margin-top: 33px">
            <v-icon @click="closeDrawer()" right :color="pickTextColorBasedOnBgColorAdvanced(selectedBSH.fill, '#fff', '#000')">mdi-close</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
                <v-list-item-title v-if="this.selectedScenario && this.selectedScenario !== this.$t('observ')">
                    {{$t('situation')}} {{$t('on')}} {{this.ipsDateTitle}} ({{this.selectedScenario.replace(this.$t('forecast'), '').charAt(0).toUpperCase() + this.selectedScenario.replace(this.$t('forecast'), '').slice(1)}}) 
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    {{$t('situation')}} {{$t('on')}} {{this.ipsDateTitle}} ({{$t(this.ipsTitle)}})
                  </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <span class="ips-icon" :style="'color: ' + selectedBSH.fill">&#9724;</span>
            </v-list-item-icon>
            <v-list-item-content>
              <v-chip>{{$t('levels')}} {{ selectedBSH.bsh.libelleClasse }}</v-chip>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-container class="text-justify" v-html="selectedBSH.description">
        </v-container>
      </template> -->
    </v-navigation-drawer>
  </div>
</template>

<script>
import mapbox from 'mapbox-gl'
import ModelService from '../services/model.service'
import SensorService from '../services/sensor.service'
import GeoAPIService from '../services/geoapi.service'
import DecreesService from '../services/decrees.service'

require('mapbox-gl/dist/mapbox-gl.css');

export default {
  name: 'ModelsMap',
  props: {
    x: Number,
    y: Number,
    zoom: Number,
    showModels: Boolean,
    showPiezos: Boolean,
    showBSH: Boolean
  },
  computed: {
    mapExtent() {
      return this.$store.state.auth.prefs.mapExtent
    }
  },
  data() {
    return {
      loadingProgress: 0,
      indeterminateLoading: false,
      currentlyLoading: '',
      ipsDate: new Date().toISOString().substr(0, 10),
      selectedScenario: null,
      availableScenarios: [this.$t('observ'), this.$t('samplingnorain'), this.$t('sampling10dry'), this.$t('sampling5dry'), this.$t('samplingMean'), this.$t('sampling5wet'), this.$t('sampling10wet')],
      ipsTitle: 'realTime', 
      ipsDateTitle: `${new Date().toLocaleDateString()}`,
      loading: false,
      accessToken: 'pk.eyJ1IjoieW5rdmdyIiwiYSI6ImNqbmVhczI0djAya3YzdnBhZ3FzdmRnbjYifQ.XwhM95oP_cRRU9V3rZg6SQ', // your access token. Needed if you using Mapbox maps
      mapStyle: 'mapbox://styles/mapbox/streets-v11?optimize=true', // your map style
      map: null,
      models: null,
      decreesL : null,
      hoveringModel: false,
      drawer: false,
      drawerBSH: false,
      selectedModel: null,
      selectedDecrees: null,
      piezo: null,
      selectedBSH: null,
      latestAdes: null,
      latestTempsReel: null,
      piezos: {
        type: 'FeatureCollection',
        features: []
      },
      modelPiezos: {
        type: 'FeatureCollection',
        features: []
      },
      pluvios: {
        type: 'FeatureCollection',
        features: []
      },
      hydro: {
        type: 'FeatureCollection',
        features: []
      },
      decrees: {
        type: 'FeatureCollection',
        features: []
      },
      pluvioIcon: [
        { name: 'synop', icon: '/images/pluvio/synop.png' }
      ],
      hydroIcon: [
        { name: 'hydro', icon: '/images/hydro/hydro.png' }
      ],
      decreesIcon: [
          { name: 'vigilance', icon:  '/images/decrees/vigilance.png', width: 12 },
          { name: 'crise', icon: '/images/decrees/crise.png', width: 12 },
          { name: 'alerte', icon: '/images/decrees/alerte.png', width: 12 },
          { name: 'alerte renforcer', icon:  '/images/decrees/renforcer.png', width: 12 },
          { name: 'alerte renforcée', icon:  '/images/decrees/renforcer.png', width: 12 },
      ],
      ipsIcons: [{ name: 'baisse-1', icon:  '/images/ips/tendance-baisse-1.svg', width: 12 },
                  { name: 'baisse-2', icon: '/images/ips/tendance-baisse-2.svg', width: 12 },
                  { name: 'baisse-3', icon: '/images/ips/tendance-baisse-3.svg', width: 12 },
                  { name: 'baisse-4', icon: '/images/ips/tendance-baisse-4.svg', width: 12 },
                  { name: 'baisse-5', icon: '/images/ips/tendance-baisse-5.svg', width: 12 },
                  { name: 'baisse-6', icon: '/images/ips/tendance-baisse-6.svg', width: 12 },
                  { name: 'baisse-7', icon: '/images/ips/tendance-baisse-7.svg', width: 12 },
                  { name: 'hausse-1', icon: '/images/ips/tendance-hausse-1.svg', width: 12 },
                  { name: 'hausse-2', icon: '/images/ips/tendance-hausse-2.svg', width: 12 },
                  { name: 'hausse-3', icon: '/images/ips/tendance-hausse-3.svg', width: 12 },
                  { name: 'hausse-4', icon: '/images/ips/tendance-hausse-4.svg', width: 12 },
                  { name: 'hausse-5', icon: '/images/ips/tendance-hausse-5.svg', width: 12 },
                  { name: 'hausse-6', icon: '/images/ips/tendance-hausse-6.svg', width: 12 },
                  { name: 'hausse-7', icon: '/images/ips/tendance-hausse-7.svg', width: 12 },
                  { name: 'stable-1', icon: '/images/ips/tendance-stable-1.svg', width: 12 },
                  { name: 'stable-2', icon: '/images/ips/tendance-stable-2.svg', width: 12 },
                  { name: 'stable-3', icon: '/images/ips/tendance-stable-3.svg', width: 12 },
                  { name: 'stable-4', icon: '/images/ips/tendance-stable-4.svg', width: 12 },
                  { name: 'stable-5', icon: '/images/ips/tendance-stable-5.svg', width: 12 },
                  { name: 'stable-6', icon: '/images/ips/tendance-stable-6.svg', width: 12 },
                  { name: 'stable-7', icon: '/images/ips/tendance-stable-7.svg', width: 12 },
                  { name: 'inconnu-inconnu', icon: '/images/ips/tendance-inconnue.svg', width: 12 },
                  { name: 'm-baisse-1', icon: '/images/ips/models/tendance-baisse-1-m.svg', width: 20 },
                  { name: 'm-baisse-2', icon: '/images/ips/models/tendance-baisse-2-m.svg', width: 20 },
                  { name: 'm-baisse-3', icon: '/images/ips/models/tendance-baisse-3-m.svg', width: 20 },
                  { name: 'm-baisse-4', icon: '/images/ips/models/tendance-baisse-4-m.svg', width: 20 },
                  { name: 'm-baisse-5', icon: '/images/ips/models/tendance-baisse-5-m.svg', width: 20 },
                  { name: 'm-baisse-6', icon: '/images/ips/models/tendance-baisse-6-m.svg', width: 20 },
                  { name: 'm-baisse-7', icon: '/images/ips/models/tendance-baisse-7-m.svg', width: 20 },
                  { name: 'm-hausse-1', icon: '/images/ips/models/tendance-hausse-1-m.svg', width: 20 },
                  { name: 'm-hausse-2', icon: '/images/ips/models/tendance-hausse-2-m.svg', width: 20 },
                  { name: 'm-hausse-3', icon: '/images/ips/models/tendance-hausse-3-m.svg', width: 20 },
                  { name: 'm-hausse-4', icon: '/images/ips/models/tendance-hausse-4-m.svg', width: 20 },
                  { name: 'm-hausse-5', icon: '/images/ips/models/tendance-hausse-5-m.svg', width: 20 },
                  { name: 'm-hausse-6', icon: '/images/ips/models/tendance-hausse-6-m.svg', width: 20 },
                  { name: 'm-hausse-7', icon: '/images/ips/models/tendance-hausse-7-m.svg', width: 20 },
                  { name: 'm-stable-1', icon: '/images/ips/models/tendance-stable-1-m.svg', width: 20 },
                  { name: 'm-stable-2', icon: '/images/ips/models/tendance-stable-2-m.svg', width: 20 },
                  { name: 'm-stable-3', icon: '/images/ips/models/tendance-stable-3-m.svg', width: 20 },
                  { name: 'm-stable-4', icon: '/images/ips/models/tendance-stable-4-m.svg', width: 20 },
                  { name: 'm-stable-5', icon: '/images/ips/models/tendance-stable-5-m.svg', width: 20 },
                  { name: 'm-stable-6', icon: '/images/ips/models/tendance-stable-6-m.svg', width: 20 },
                  { name: 'm-stable-7', icon: '/images/ips/models/tendance-stable-7-m.svg', width: 20 },
                  { name: 'm-inconnu-inconnu', icon: '/images/ips/models/tendance-inconnue-m.svg', width: 20 }],
      search: null,
      isSearchLoading: false,
      searchItems: [],
      searchModel: null,
      searchTab: null
    }
  },
  created () {
      this.$store.subscribe((mutation, state) => {
          if (mutation.type === 'modelsmap/toggleLayerSuccess') {
              let layer = state.modelsmap.layerName
              let visible = state.modelsmap.visible
              this.map.setLayoutProperty(layer, 'visibility', visible ? 'visible' : 'none');
          }
      })
  },
  watch: {
    search (val) {
      if(!val || val.length < 2) {
        return;
      }
      
      this.isSearchLoading = true

      GeoAPIService.search(val).then(d => {
        if(d && d.features && d.features.length > 0) {
          this.searchItems = d.features
        }
        else {
          this.searchItems = []
        }

        
        let filteredPiezos = this.piezos.features.filter(p => new RegExp(`.*${val}.*`, 'gi').test(p.properties.codeBss))
        this.searchItems = this.searchItems.concat(filteredPiezos)
      }).catch(err => {
        console.error(err)
      }).finally(() => this.isSearchLoading = false)

      this.isSearchLoading = true
    },
    searchModel (val) {
      if(val && val.coordinates) {
          this.map.flyTo({
          center: val.coordinates,
          zoom: 12
      })
      } else {
        this.map.flyTo({
          center: [this.x, this.y],
          zoom: this.zoom
        })
      }
    }
  },
  async mounted() {
    // We need to set mapbox-gl library here in order to use it in template
    mapbox.accessToken = this.accessToken

    // eslint-disable-next-line no-undef
    this.map = new mapbox.Map({
      container: 'modelsMap',
      style: {
        version: 8,
        sources: {
          'osm': {
            type: 'raster',
            tiles: [
              'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
              'https://b.tile.openstreetmap.org/{z}/{x}/{y}.png',
              'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png'
            ],
            tileSize: 256,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          }
        },
        layers: [
          {
            id: 'osm-layer',
            type: 'raster',
            source: 'osm',
            minzoom: 0,
            maxzoom: 22
          }
        ]
      },
      center: [this.x, this.y],
      zoom: this.zoom
    })
    var $this = this
    this.map.on('load', async function() {
      $this.loadData()
      
      $this.map.addSource('piezos', {
        type: 'geojson',
        data: $this.piezos
      })

      $this.map.addSource('modelPiezos', {
        type: 'geojson',
        data: $this.modelPiezos
      })

      $this.map.addSource('pluvios', {
        type: 'geojson',
        data: $this.pluvios
      })

      $this.map.addSource('hydro', {
        type: 'geojson',
        data: $this.hydro
      })

      $this.map.addSource('decrees', {
        type: 'geojson',
        data: $this.decrees
      })
      $this.map.addLayer({
        id: 'hydro',
        type: 'symbol',
        source: 'hydro',
        layout: {
          'icon-image': '{hydroIcon}',
          'icon-allow-overlap': true,
          'icon-size': 0.10,
          'visibility': 'none'
        }
      })

      $this.map.addLayer({
        id: 'pluvios',
        type: 'symbol',
        source: 'pluvios',
        layout: {
          'icon-image': '{pluvioIcon}',
          'icon-allow-overlap': true,
          'icon-size': 0.10,
          'visibility': 'none'
        }
      })

      $this.map.addLayer({
        id: 'decrees',
        type: 'symbol',
        source: 'decrees',
        layout: {
          'icon-image': '{decreesIcon}',
          'icon-allow-overlap': true,
          'icon-size': 0.5,
          'visibility': 'none'
        }
      })

      $this.map.addLayer({
        id: 'piezos',
        type: 'symbol',
        source: 'piezos',
        layout: {
          'icon-image': '{ipsIcon}',
          'icon-allow-overlap': true,
          'icon-size': 1,
          'visibility': 'none'
        }
      })

      $this.map.addLayer({
        id: 'modelPiezos',
        type: 'symbol',
        source: 'modelPiezos',
        layout: {
          'icon-image': '{ipsIcon}',
          'icon-allow-overlap': true,
          'icon-size': 1
        }
      })

      // Gère l'événement click sur un symbole de modèle
      $this.map.on('click', 'modelPiezos', function(e) {
        var modelId = e.features[0].properties.modelId
        var model = $this.models.filter(m => m.id === modelId)[0]
        model.ips = JSON.parse(e.features[0].properties.ips)
        $this.openDrawer(model)
      })
 
      // Change le curseur en pointeur lorsqu'il passe sur un symbole de modèle.
      $this.map.on('mouseenter', 'modelPiezos', function () {
        $this.map.getCanvas().style.cursor = 'pointer';
        $this.hoveringModel = true;
      });
      
      // Remet le curseur par défaut lorsqu'il sort d'un symbole de modèle.
      $this.map.on('mouseleave', 'modelPiezos', function () {
        $this.map.getCanvas().style.cursor = '';
        $this.hoveringModel = false;
      });

      // Gère l'événement click sur un symbole de modèle
      $this.map.on('click', 'decrees', function(e) {
      var decreesid = e.features[0].properties.id
      var decreesSelect = $this.decreesL.filter(d => d.id === decreesid)[0]
      decreesSelect.zoneArrete = JSON.parse(e.features[0].properties.zoneArrete)
      $this.openDrawerDecrees(decreesSelect)
      })
    
      // Change le curseur en pointeur lorsqu'il passe sur un symbole de modèle.
      $this.map.on('mouseenter', 'decrees', function () {
        $this.map.getCanvas().style.cursor = 'pointer';
        $this.hoveringModel = true;
      });
      
      // Remet le curseur par défaut lorsqu'il sort d'un symbole de modèle.
      $this.map.on('mouseleave', 'decrees', function () {
        $this.map.getCanvas().style.cursor = '';
        $this.hoveringModel = false;
      });

      // eslint-disable-next-line no-undef
      let scale = new mapbox.ScaleControl()
      $this.map.addControl(scale, 'top-left');

      if($this.mapExtent && $this.mapExtent.length === 2) {
        $this.map.fitBounds($this.mapExtent);
      }
    })
  },
  methods: {
    async loadData() {
      this.loadIPSIcons()
      this.loadPluvioIcons()
      this.loadDecreesIcons()
      this.loadHydroIcons()
      this.loading = true
      await this.loadModels()
      await this.loadPiezos()
      await this.loadPluvios()
      await this.loadHydro()
      await this.loadDecrees()
      this.loading = false
      this.loadingProgess = 100
      this.indeterminateLoading = false
    },
    async loadModels(date, scenario) {
      try {
        this.models = await ModelService.getModel()
        if(this.models) {
          this.loadingProgress = 1
          this.indeterminateLoading = false
          this.currentlyLoading = this.$t('currentModelCalcul')
          for(let model of this.models) {
            let p = this.filterPiezosFromModel(model)

            if(!p)
              continue;

            this.loadingProgress += (100 / parseInt(this.models.length))
            this.currentlyLoading = `${this.$t('loadModel')} ${p.capteur.identifier}`

            if(p && p.capteur.coords) {
              p.capteur.coords.properties = {
                codeBss: p.capteur.identifier,
                ipsIcon: 'm-inconnu-inconnu',
                modelId: model.id,
                ips: {
                  couleur: "#C2C2C2",
                  tendance: 'Tendance inconnue',
                  classe: 'Inconnue',
                  symbole: '&#9675;',
                  libelleClasse: this.$t('unknown')
                }
              }
              try {
                let ips = await ModelService.getIPS(p.capteur.identifier, date, scenario)
                if(ips && ips.tendance && ips.classe) {
                  p.capteur.coords.properties.ipsIcon = `m-${ips.tendance}-${ips.classe}`
                  p.capteur.coords.properties.ips = ips
                }
                this.modelPiezos.features.push(p.capteur.coords)
              } catch(e) {
                this.modelPiezos.features.push(p.capteur.coords)
              }
            }            
            this.map.getSource('modelPiezos').setData(this.modelPiezos)
          }
          
          this.loadingProgress = 100
          this.indeterminateLoading = false
          this.currentlyLoading = this.$t('currentModelLoadDone')
        }
      } catch (e) {
        console.error(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async loadBassins(date) {
      return;

      /// Pas utilisé pour le moment
      /// Supprimer le return au dessus pour réactiver cette méthode
      // eslint-disable-next-line no-unreachable
      this.currentlyLoading = 'Chargement des secteurs BSH'
      this.indeterminateLoading = true
      var allBassins = await ModelService.getAllBSH()
      this.indeterminateLoading = false
      this.loadingProgress = 1

      if(allBassins) {
        for(let b of allBassins) {
          this.currentlyLoading = `Chargement du secteur ${b.nom}`
          this.loadingProgress += (100 / parseInt(allBassins.length))

          var bassin = await ModelService.getBSHGeometry(b.id, 0.0001)

          if(bassin && bassin.coordinates) {
            try {
              var bshValue = await ModelService.computeBSH(b.id)

              if(bshValue && bshValue.couleur) {
                this.bshBassins.features.push({ type: 'Feature', geometry: bassin, properties: { fill: bshValue.couleur } })
              } else {
                this.bshBassins.features.push({ type: 'Feature', geometry: bassin, properties: { fill: '#808080' } })
              }
            }
            catch(e) {
              this.bshBassins.features.push({ type: 'Feature', geometry: bassin, properties: { fill: '#808080' } })
            }

            this.map.getSource('bshBassins').setData(this.bshBassins)
          }
        }
      }


      this.currentlyLoading = 'Chargement des secteurs terminé'
      this.indeterminateLoading = false
      this.loadingProgress = 100
    },
    async loadPiezos(date, scenario) {
      let d = new Date()
      let limitDate = new Date(`${d.getUTCFullYear()}/${(d.getMonth() + 1)}/${d.getUTCDate()}`)
      limitDate.setDate(limitDate.getDate() + 1)


      var piezos = await SensorService.getSensors('Piezo')
      if(piezos) {
        let filteredPiezos = piezos.filter(x => !this.modelPiezos.features.map(mp => mp.properties.codeBss).some(i => i === x.identifier))
        this.loadingProgress = 1
        this.indeterminateLoading = true
        this.currentlyLoading = this.$t('calculIPS')
        let allIps = []

        if(!date || new Date(date) < limitDate) {
          allIps = await ModelService.getAllIPS(filteredPiezos.map(p => p.identifier), date, scenario);
        }

        this.indeterminateLoading = false
        this.loadingProgress = 1
        for(let p of filteredPiezos) {
          if(p && p.coords) {
            p.coords.properties = {
              codeBss: p.identifier,
              ipsIcon: 'inconnu-inconnu',
              bssId: p.altIdentifier,
              label: p.identifier
            }
            try {
              let ips = allIps.filter(x => x.identifier === p.identifier).pop() // await ModelService.getIPS(p.identifier, date, scenario)
              if(ips && ips.tendance && ips.classe) {
                p.coords.properties.ipsIcon = `${ips.tendance}-${ips.classe}`
                this.piezos.features.push(p.coords)
              } else {
                p.coords.properties.ipsIcon = `inconnu-inconnu`
                this.piezos.features.push(p.coords)
              }
            } catch {
              console.warn(`Impossible de récupérer l'IPS du Piezo ${p.identifier}`)
              this.piezos.features.push(p.coords)
            }
            this.map.getSource('piezos').setData(this.piezos)
          }
          
          this.loadingProgress += (100 / parseInt(filteredPiezos.length))
          this.currentlyLoading = this.$t('loadPiezo') + p.identifier
        }

        this.loadingProgress = 100 
        this.currentlyLoading = this.$t('endLoadPiezo')
      }
    },
    async loadPluvios() {
      this.currentlyLoading = this.$t('loadRainGauge')
      this.indeterminateLoading = true
      var pluvios = await SensorService.getSensors('Pluie', 'Synop')
      this.indeterminateLoading = false
      this.loadingProgress = 1
      if(pluvios) {
        for(let p of pluvios) {
          this.currentlyLoading = `${this.$t('loadOneRainGauge')} ${p.identifier}`
          this.loadingProgress += (100 / parseInt(pluvios.length))
          if(p && p.coords) {
            p.coords.properties = {
              identifier: p.identifier,
              pluvioIcon: 'synop'
            }

            this.pluvios.features.push(p.coords)
          }
        }

        this.map.getSource('pluvios').setData(this.pluvios)
      }

      this.currentlyLoading = this.$t('endLoadRainGauge')
      this.loadingProgress = 100
    },
    async loadHydro() {
      this.currentlyLoading = this.$t('loadHydroStations')
      this.indeterminateLoading = true
      var hydro = await SensorService.getHubeauHydroSensors()
      this.indeterminateLoading = false
      this.loadingProgress = 1

      if(hydro) {
        
        for(let h of hydro) {
          this.loadingProgress += (100 / parseInt(hydro.length))
          this.currentlyLoading = `${this.$t('loadOneHydroStation')} ${h.identifier}`
          h.properties.hydroIcon = 'hydro'
        }

        this.hydro.features = hydro

        this.map.getSource('hydro').setData(this.hydro)
      }

      this.currentlyLoading = this.$t('endLoadHydroStations')
      this.loadingProgress = 1
    },
    async loadDecrees() {
      this.currentlyLoading = this.$t('loadDecrees')
      this.indeterminateLoading = true
      this.decreesL = await DecreesService.getLatestDecrees()
      this.indeterminateLoading = false
      this.loadingProgress = 1
      if(this.decreesL) {
        for(let p of this.decreesL) {
          this.currentlyLoading = `${this.$t('loadOneRainGauge')} ${p.zoneArrete.nomZone}`
          this.loadingProgress += (100 / parseInt(this.decreesL.length))
          if(p && p.zoneArrete.coords) {
            p.zoneArrete.coords.properties = {
              id: p.id,
              decreesIcon: p.nomNiveau.toLowerCase(),
              zoneArrete:{
                nomZone:p.zoneArrete.nomZone,
                codeDep:p.zoneArrete.codeDep,
                nomDep: p.zoneArrete.nomDep,
                typeZone: p.zoneArrete.typeZone
              },
              decreesName: p.nomArrete,
              download: p .cheminTelechargement,
              dateDebut: p.dateDebut,
              datefin: p.datefin
            }

            this.decrees.features.push(p.zoneArrete.coords)
          }
        }

        this.map.getSource('decrees').setData(this.decrees)
      }

      this.currentlyLoading = this.$t('endLoadDecrees')
      this.loadingProgress = 100
    },
    getColorAlert(alerte){
      switch(alerte){
        case 1:
          return "#D3D3D3"
        case 3:
          return "#FFDB58"
        case 4:
          return "#FF7F00"
        case 5:
          return "#F00020"
        default:
          return "#D3D3D3"
      }
    },
    async getIPS(bssCode) {
      try {
        let ips = await ModelService.getIPS(bssCode)
        return Promise.resolve(ips)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async IPSDateChanged() {
      this.modelPiezos.features = []
      this.piezos.features = []
      this.closeDrawer()
      let d_ipsDate = new Date(this.ipsDate + " 00:00:00")
      let d_now = new Date(new Date().toDateString() + " 00:00:00")

      if(d_ipsDate.getTime() <= d_now.getTime())
        this.selectedScenario = this.$t('observ')
      else if(d_ipsDate.getTime() > d_now.getTime() && this.selectedScenario === this.$t('observ'))
        this.selectedScenario = this.$t('samplingMean')

      if(this.selectedScenario && this.selectedScenario !== this.$t('observ')) { 
        this.ipsTitle = 'forecast'

      }
      else if (!this.selectedScenario && d_ipsDate.getTime() === d_now.getTime()) {
        this.ipsTitle = 'realTime'
      }
      else {
        this.selectedScenario = 'Observé'
        this.ipsTitle = 'previousSituation'
      }
      this.map.getSource('piezos').setData(this.piezos)
      this.map.getSource('modelPiezos').setData(this.modelPiezos)
      this.loading = true
      await this.loadPiezos(this.ipsDate, this.selectedScenario)
      await this.loadModels(this.ipsDate, this.selectedScenario)
      await this.loadBassins(this.ipsDate)
      this.loading = false
      this.loadingProgess = 100
      this.indeterminateLoading = false
    },
    async resetIPS() {
      this.ipsDate = new Date().toISOString().substr(0, 10)
      this.selectedScenario = null
      await this.IPSDateChanged()
    },
    createMarker(symbol, clazz, color) {
      let markerElement = document.createElement('div')
      markerElement.innerHTML = symbol
      markerElement.className = clazz
      markerElement.style.color = color

      return markerElement
    },
    loadIPSIcons() {
      var $this = this
      this.ipsIcons.forEach(i => {
        let img = new Image(i.width, i.width)
        img.onload = ()=>$this.map.addImage(i.name, img)
        img.src = window.location.origin + i.icon
      })
    },
    loadPluvioIcons() {
      var $this = this
      this.pluvioIcon.forEach(i => {
        $this.map.loadImage(window.location.origin + i.icon, function(error, image) {
          if(!error) {
            $this.map.addImage(i.name, image)
          }
          else{
            console.error(error)
          }
        })
      })
    },
    loadDecreesIcons() {
      var $this = this
      this.decreesIcon.forEach(i => {
        $this.map.loadImage(window.location.origin + i.icon, function(error, image) {
          if(!error) {
            $this.map.addImage(i.name, image)
          }
          else{
            console.error(error)
          }
        })
      })
    },
    loadHydroIcons() {
      var $this = this
      this.hydroIcon.forEach(i => {
        $this.map.loadImage(window.location.origin + i.icon, function(error, image) {
          if(!error) {
            $this.map.addImage(i.name, image)
          }
          else{
            console.error(error)
          }
        })
      })
    },
    filterPiezosFromModel(model){
      if(model && model.donneeModele && model.donneeModele.length > 0) {
        let filteredDonneeModele = model.donneeModele.filter(m => m.capteur?.typeDonnee?.type === "Piezo");

        if(filteredDonneeModele && filteredDonneeModele.length > 0) {
          return filteredDonneeModele.reduce((m, index) => m[index])
        }
      }

      return null;
    },
    getModelCoordinates(model){
      return model.capteur.coords.geometry.coordinates
    },
    openDrawer (model) {
      if(this.drawer || this.drawerBSH) {
        this.closeDrawer()
      }
      this.selectedModel = model
      this.piezo = this.filterPiezosFromModel(model)
      let piezo = this.filterPiezosFromModel(model)
      SensorService.getLatestSensorData('Piezo', piezo.capteur.identifier).then(d => {
        this.latestAdes = `${new Date(d.date).toLocaleDateString()} : ${d.value} mNGF`
      }).catch(() => {
        this.latestAdes = null
      })
      
      SensorService.getRealTimePiezoData(piezo.capteur.identifier, new Date().toISOString().split(/T/)[0]).then(d => {
        if(d && d.length > 0)
          this.latestTempsReel = `${new Date(d[d.length - 1].date).toLocaleString()} : ${d[d.length - 1].value} mNGF`
      }).catch(() => {
        this.latestTempsReel = null
      })

      this.drawer = true
    },
    openDrawerBSH (selectedBSH) {
      if(this.drawerBSH || this.drawer) {
        this.closeDrawer()
      }

      selectedBSH.bsh = JSON.parse(selectedBSH.bsh)

      this.selectedBSH = selectedBSH
      this.drawerBSH = true
    },
    openDrawerDecrees (decreesSelect) {
      if(this.drawer || this.drawerBSH) {
        this.closeDrawer()
      }
      this.selectedDecrees = decreesSelect
      this.drawer = true
    },
    closeDrawer(){
      this.drawer = false
      this.drawerBSH = false
      this.selectedModel = null
      this.latestAdes = null
      this.latestTempsReel = null
      this.selectedBSH = null
      this.selectedDecrees = null
    },
    pickTextColorBasedOnBgColorAdvanced(bgColor, lightColor, darkColor) {
      var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
      var r = parseInt(color.substring(0, 2), 16); // hexToR
      var g = parseInt(color.substring(2, 4), 16); // hexToG
      var b = parseInt(color.substring(4, 6), 16); // hexToB
      var uicolors = [r / 255, g / 255, b / 255];
      var c = uicolors.map((col) => {
        if (col <= 0.03928) {
          return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
      });
      var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
      return (L > 0.179) ? darkColor : lightColor;
    },
    saveCurrentExtent() {
      var bounds = this.map.getBounds().toArray()
      this.$store
        .dispatch("auth/saveMapExtent", bounds)
        .then(() => {
          this.$store.dispatch("messages/showMessage", {content: this.$t('savePosMap'), color: "success", timeout: 5000 });
        });
    },
    downloadButtonAction(url){
      window.open(url)
      // window.URL.get(url, { responseType: 'blob' })
      // .then(response => {
      //   const blob = new Blob([response.data], { type: 'application/pdf' })
      //   const link = document.createElement('a')
      //   link.href = URL.createObjectURL(blob)
      //   link.download = nomArrete
      //   link.click()
      //   URL.revokeObjectURL(link.href)
      // }).catch(console.error)
    }
  }
}
</script>
<style>
.mapboxgl-canvas, .mapboxgl-canvas:focus {
  border: none !important;
  outline: none !important;
}
.mapboxgl-marker{
  cursor: pointer;
}
.mapboxgl-marker.active {
  transition: text-shadow 300ms;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
}
.mapboxgl-ctrl-top-left {
  z-index: 1 !important;
}
.marker-ips{
  transition: text-shadow 300ms;
  font-size: 30px;
  width: 30px;
  height: 30px;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
}
.ips-icon {
  font-size: 32px;
  position: relative;
  top: -6px;
  -webkit-text-stroke: 2px black;
  text-shadow: 1px 1px 2px black;
}
.preloader{
  z-index: 99999999999999;
  position: absolute;
  width: 550px;
  top: 10px;
  left: calc(50% - 275px);
}
.loadingCaption {
  width: 500px;
  display: inline-block;
  padding: 2px;
}
.v-progress-circular__overlay, .v-progress-circular__underlay {
  transition: none !important;
}
.map-toolbox {
  z-index: 2;
  position: absolute;
  width: 450px;
  bottom: 20px;
  right: 20px;
}
.map-legends {
  z-index: 2;
  position: absolute;
  width: 350px;
  bottom: 20px;
  left: 20px;
}

.map-legends .legende img {
  width: 26px;
  height: 26px;
  float: left;
  margin-top: -2px;
}

.legende.ips img.grayscale {
  filter: grayscale(100%);
}
.legende .legend-item img {
  margin-right: 20px;
}
.legende .legend-item {
  margin-top: 5px;
  text-align: left;
}
.legende .legend-title {
  font-weight: bold;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
  border-bottom: 1px solid rgba(192, 192, 192, 0.555);
}

.mapsearch {
    position: absolute;
    left: calc(50% - 300px);
    top: 50px;
    width: 600px;
    height: 40px;
    z-index: 99999;
    display: inline-block;
}

.btn-save-map-state {
  position: absolute;
  bottom: 0;
  left: 0;
}

.ips-selector {
  width: 380px !important;
  float: right !important;
}
</style>