const API_BASE = 'https://api-adresse.data.gouv.fr/search/?limit=5&q='


class GeoAPIService {
    async search(query){
        let responseAddress = await fetch(`${API_BASE}${encodeURIComponent(query)}`, {
            method: 'GET'
        })

        if(responseAddress && responseAddress.ok) {
            let addresses = await responseAddress.json()
    
            return addresses
          } else {
              throw new Error(responseAddress.statusText)
        }
    }
}

export default new GeoAPIService()